'use client'

import { Link } from '@/navigation'
import scrollToID from '@/utils/scrollToID'

export default function CCP_LinkWrapper({
  path = '',
  children,
  className = '',
  target = null,
}) {
  if (!path) {
    return <div className={className}>{children}</div>
  }
  if (path?.includes('#')) {
    const id = path?.split('#')[1]
    return (
      <div onClick={() => scrollToID(id)} className={className}>
        {children}
      </div>
    )
  } else {
    return (
      <Link
        prefetch={false}
        href={`${path}`}
        target={target}
        className={className}
      >
        {children}
      </Link>
    )
  }
}
