import { useBreakpoint } from '@/utils/responsive'
import React from 'react'
import ArrowMenuItem from '../button/ArrowMenuItem'
import CCP_LinkWrapper from '../element/LinkWrapper'
import SCP_CoinIcon from '../b-point/widgets/CoinIcon'

export default function SCP_SidebarMenuItem({
  menu,
  activeSlugMainMenu,
  activeSlugSubMenu,
}) {
  const isMd = useBreakpoint('md')
  let isMainMenuActive =
    menu.activeSlug &&
    activeSlugMainMenu &&
    menu.activeSlug === activeSlugMainMenu

  if (!isMd && menu.hideOnMobile) return null
  // B point menu
  const isBpoint = menu?.activeSlug === 'b-point'
  let textHoverColor = 'group-hover:tw-text-bgh-blue-bright'
  if (isBpoint) {
    textHoverColor = 'group-hover:tw-text-bgh-blue tw-text-bgh-primary'
  }

  return (
    <div className="tw-h-full">
      {/* menu */}
      <CCP_LinkWrapper
        path={menu.path}
        target={menu?.target}
        className="tw-h-full"
      >
        <div
          className={`tw-border md:tw-border-0 tw-border-transparent tw-flex md:tw-items-center md:tw-flex-row tw-flex-col md:tw-space-x-4 rtl:tw-space-x-reverse md:tw-space-y-0 tw-space-y-2 tw-group ltr:md:-tw-ml-12 rtl:md:-tw-mr-12 max-md:tw-bg-white md:!tw-shadow-none tw-shadow-main-blue-light  max-md:tw-p-2 max-md:tw-rounded-md max-md:tw-h-full ${
            menu.path ? 'tw-cursor-pointer' : 'tw-pointer-events-none'
          } ${isMainMenuActive ? '!tw-shadow-main-blue !tw-border-bgh-blue-fade' : ''}`}
        >
          {isBpoint ? (
            <SCP_CoinIcon
              level="min"
              className={`tw-size-8 tw-transition-all tw-duration-300 group-hover:tw-scale-110 ${isMainMenuActive ? 'tw-scale-110' : ''}`}
            />
          ) : (
            <div
              className={`tw-size-8 md:tw-rounded-full tw-rounded-lg center-child tw-flex-none  ${
                isMainMenuActive
                  ? 'tw-bg-bgh-blue animated-gradient-blue'
                  : 'tw-bg-bgh-blue-alpha'
              }`}
            >
              <i
                className={` group-hover:tw-scale-110 tw-duration-200 far  fa-${
                  menu.icon
                } ${isMainMenuActive ? 'tw-text-white' : 'tw-text-bgh-blue'}`}
              />
            </div>
          )}
          <h5
            className={`group-hover:md:tw-translate-x-1 ${textHoverColor} ${isMainMenuActive ? 'tw-text-bgh-blue-bright' : ''} tw-duration-200 md:tw-text-lg`}
          >
            {menu.title}
          </h5>
        </div>
      </CCP_LinkWrapper>
      {/* submenu desktop */}
      {menu.submenu && isMd ? (
        <div className="tw-flex tw-flex-col tw-space-y-2 tw-my-2">
          {menu.submenu.map((subMenu, j) => (
            <ArrowMenuItem
              key={`center-submenu-${j}`}
              menu={subMenu}
              isActive={
                subMenu.activeSlug &&
                activeSlugSubMenu &&
                subMenu.activeSlug === activeSlugSubMenu
              }
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}
